import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext'; 
import LoadingSpinner from '../components/LoadingSpinner';
import { 
  fetchUserResumes, 
  fetchUserCoverLetters 
} from '../services/resumeService'; 
import { createNewResumeWithAI } from '../services/aiResumeCreateService';
import Modal from '../components/Modal';
import LoginModal from '../components/LoginModal';
import { CgClose } from "react-icons/cg";
import { GiStarsStack } from "react-icons/gi";
 
const CvExampleNew = () => {
  const { user, loading: authLoading } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [resumes, setResumes] = useState([]);
  const [coverLetters, setCoverLetters] = useState([]);
  const [operationLoading, setOperationLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isAILoading, setIsAILoading] = useState(false); 
  const [isAIFormVisible, setIsAIFormVisible] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false); 
  const [aiErrorMessage, setAiErrorMessage] = useState('');

  const [lastResumeVisible, setLastResumeVisible] = useState(null);
  const [lastCoverLetterVisible, setLastCoverLetterVisible] = useState(null); 
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    jobTitle: '',
    email: '',
    phone: '',
    yearsOfExperience: 0,
  });

 

  useEffect(() => {
    if (!authLoading) {
      if (user) {
        fetchInitialData();
      } else {         
        setIsLoading(false);
      }
    }
  }, [user, authLoading]);

  useEffect(() => {
    // Extract job title from URL and trigger AI resume creation
    const queryParams = new URLSearchParams(location.search);
    const cvexample = queryParams.get('jobtitle');
    setIsAILoading(true)
    if (cvexample && user) {
      const jobTitle = cvexample
        .split('-')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
      
      const firstNames = [
        'Diana', 'Hannah', 'Jennifer', 'Stella', 'Ryan', 'Victor',
        'Sophia', 'Olivia', 'Emma', 'Mary', 'Isabella', 'Samuel', 'Sarah', 'Shreya', 
        'John', 'Evelyn', 'Abigail', 'Nora', 'Scarlett', 'Grace', 'Samuel', 'Noah', 
        'William', 'James', 'Benjamin', 'Ethan', 'Henry', 'Alexander', 'Daniel'
      ];

      const lastNames = [
        'Powell', 'Roberts', 'Murray', 'Stewart', 'Turner',
        'Johnson', 'Williams', 'Jones', 'Brown', 'Davis', 
        'Miller', 'Wilson', 'Moore', 'Taylor', 'Anderson', 
        'Thomas', 'Jackson', 'White', 'Harris', 'Martin'
      ];

      const randomFirstName = firstNames[Math.floor(Math.random() * firstNames.length)];
      const randomLastName = lastNames[Math.floor(Math.random() * lastNames.length)];
      const randomExperience = Math.floor(Math.random() * 11) + 6; // Random number between 6 and 15

      const formDataWithJobTitle = {
        firstName: randomFirstName,
        lastName: randomLastName,
        jobTitle: jobTitle,
        email: 'support@resumedesign.ai',
        phone: '(555) 345-6789',
        yearsOfExperience: randomExperience,
      };

      handleCreateNewResumeWithAI(formDataWithJobTitle);
    }
  }, [location, user]);

  const fetchInitialData = async () => {
    setIsLoading(true);
    try {
        const resumeData = await fetchUserResumes(user.uid, null);
        const coverLetterData = await fetchUserCoverLetters(user.uid, null);
        setResumes(Array.isArray(resumeData.resumes) ? resumeData.resumes : []); // Ensure resumes is always set as an array
        setCoverLetters(Array.isArray(coverLetterData.coverLetters) ? coverLetterData.coverLetters : []); // Ensure coverLetters is always set as an array
        setLastResumeVisible(resumeData.lastVisible);
        setLastCoverLetterVisible(coverLetterData.lastVisible);
    } catch (error) {
        console.error('Failed to fetch initial data:', error);
        setResumes([]);
        setCoverLetters([]);
    } finally {
        setIsLoading(false);
    }
};



  const EngagingTexts = [
    "Hang tight! We're building your resume.",
    "Just a moment, your resume is on the way!",
    "Almost there, polishing up the final details!",
    "Grab a coffee while we wrap this up!",
    "Final touches being added to your masterpiece!"
  ];
  
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTextIndex(prevIndex => (prevIndex + 1) % EngagingTexts.length);
    }, 3000); 

    return () => clearInterval(intervalId);
  }, []);

  const tabs = [
    { id: 'resume', title: 'Resumes' },
    { id: 'coverLetter', title: 'Cover Letters' },
  ];
 

  const handleCreateNewResumeWithAI = async (customFormData) => {
    setAiErrorMessage('');
    if (user) {
      setIsAIFormVisible(true);
    } else {
      setIsLoginModalOpen(true); 
      return;
    }

    const formDataWithUserID = {
      ...customFormData,
      userID: user.uid,
    };
    try {
      await createNewResumeWithAI(formDataWithUserID, navigate);
      fetchUserResumes(user.uid).then(setResumes);
      setIsAIFormVisible(false); 
    } catch (error) {
      console.error("Failed to create new resume with AI:", error);
      setAiErrorMessage('Failed to create new resume with AI. Please try later.');
    } finally {
      setIsAILoading(false);
    }
  };


  if (authLoading || operationLoading) {
    return <LoadingSpinner />;
  }


  
 const handleAIFormSubmit = async (e) => {
  e.preventDefault(); 
  setIsAILoading(true);
  setAiErrorMessage(''); 
  try {
    const formDataWithUserID = {
      ...formData,
      userID: user.uid,
      yearsOfExperience: formData.yearsOfExperience,
    };
    await createNewResumeWithAI(formDataWithUserID, navigate);
    fetchUserResumes(user.uid).then(setResumes);
    setIsAIFormVisible(false); 
  } catch (error) {
    console.error("Failed to create new resume with AI:", error);
    setAiErrorMessage('Failed to create new resume with AI. Please try later.');
  } finally {
    setIsAILoading(false);
  }
};

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  if (isLoading) {
    return (
      <>
        <LoadingSpinner />
      </>
    );
  }

  return (   
    <div className='main-dashboard'>
   
   
<Modal isOpen={isAIFormVisible} closeModal={() => setIsAIFormVisible(false)} title={isAILoading ? '' : 'Create Resume with AI'}>
  {isAILoading ? (
    <div className="text-center h-[150px]">
      <p className="mt-2 opacity-100 font-bold text-smx2 transition-opacity duration-1000 ease-in-out">
        {EngagingTexts[currentTextIndex]}
      </p>
      <div className='h-[120px] flex items-center justify-center'><LoadingSpinner displayType="normal" className="loadingSpin" /></div>
    </div>
  ) : (
    <form onSubmit={handleAIFormSubmit} className="space-y-4">
      {aiErrorMessage && <p className="text-red-500">{aiErrorMessage}</p>}
      <div className="grid mt-8 grid-cols-2 gap-4">
        <label className="block">
          First Name:
          <input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleFormChange}
            required
            className="mt-1 block w-full border border-gray-300 rounded-md p-2"
          />
        </label>
        <label className="block">
          Last Name:
          <input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleFormChange}
            required
            className="mt-1 block w-full border border-gray-300 rounded-md p-2"
          />
        </label>
      </div>
      <label className="block">
        Job Title:
        <input
          type="text"
          name="jobTitle"
          value={formData.jobTitle}
          onChange={handleFormChange}
          required
          className="mt-1 block w-full border border-gray-300 rounded-md p-2"
        />
      </label>
      <label className="block">
        Email:
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleFormChange}
          required
          className="mt-1 block w-full border border-gray-300 rounded-md p-2"
        />
      </label>
      <div className="grid grid-cols-2 gap-4">
        <label className="block">
          Phone:
          <input
            type="text"
            name="phone"
            value={formData.phone}
            onChange={handleFormChange}
            required
            className="mt-1 block w-full border border-gray-300 rounded-md p-2"
          />
        </label>
        <label className="block">
          Experience:
          <input
            type="number"
            name="yearsOfExperience"
            value={formData.yearsOfExperience}
            onChange={handleFormChange}
            required
            className="mt-1 block w-full border border-gray-300 rounded-md p-2"
          />
        </label>
      </div>
      <button 
    type="submit" 
    className="w-full flex text-center justify-center bg-blue-500 text-white p-2 rounded-md ai-writer-btn create-resume-btn"
    >
      <GiStarsStack className="mr-2"/> 
      Create Resume
  </button>
    </form>
  )}
</Modal>


      {isLoginModalOpen && <LoginModal isOpen={isLoginModalOpen} closeModal={() => setIsLoginModalOpen(false)} />}

    </div>    
  );
};

export default CvExampleNew;
